@import './settings';
@import './flex-layout';
@import './cubic-bezier';

.rs-wrapper-v4 {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    padding: 0 24px;

    @media (max-width: 600px) {
        padding: 0 16px;
    }
}

.grid {
    display: grid;
    grid-gap: $gutter-xsmall;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 600px) {
        grid-gap: $gutter;
    }
}

@each $class in $column-class {
    .grid.#{nth($class, 1)} {
        grid-template-columns: repeat(nth($class, 2), 1fr);
    }
}

@each $class in $column-class {
    .grid.#{nth($class, 1)}-md {
        @media (max-width: 1439px) {
            grid-template-columns: repeat(nth($class, 2), 1fr);
        }
    }
}

@each $class in $column-class {
    .grid.#{nth($class, 1)}-sm {
        @media (max-width: 959px) {
            grid-template-columns: repeat(nth($class, 2), 1fr);
        }
    }
}

@each $class in $column-class {
    .grid.#{nth($class, 1)}-xs {
        @media (max-width: 599px) {
            grid-template-columns: repeat(nth($class, 2), 1fr);
        }
    }
}

@each $class-name, $min-size, $min-width in $col-class-responsive {
    .grid.#{nth($class-name, 1)}-responsive {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

        @media (min-width: $min-width) {
            grid-template-columns: repeat(auto-fill, minmax($min-size, 1fr));
        }
    }
}

.rs-link-info-block,
.rs-link,
.rs-link-white {
    color: rs-color-primary();
    text-decoration: none;
    transition: 0.1s $primary-cubic;
    cursor: pointer;
}

.rs-link-info-block {
    font-size: 1.313rem;
}

.rs-link-white {
    color: white;
}

.rs-link-info-block:hover,
.rs-link-white:hover,
.rs-link:hover {
    text-decoration: underline;
}

.tooltip {
    display: none;
    width: 180px;
    background-color: $info-color-secondary;
    color: $info-color-primary;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px;
    position: absolute;
    top: 24px;
    left: -32px;
    right: 0;
    font-size: 0.75rem;
    z-index: 1;
}

.show-tooltip:hover ~ app-tooltip .tooltip {
    display: block;
}

#submit-component mat-spinner circle {
    stroke: white !important;
}

.conf-basic-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.mat-card {
    padding: 0 !important;
    color: $primary-text;
}

.mat-card app-dark-title {
    margin: 0 !important;
}

.mat-menu-item {
    color: $primary-text;
}

.mat-card > * {
    margin: 0;
    padding: 24px 24px 0;

    &:last-child {
        padding-bottom: 24px;
    }
}

.mat-card .mat-card-header-text {
    margin: 0 !important;
}

.mat-card > .mat-divider {
    position: relative !important;
    padding: 0 !important;
    margin: 24px 0 0 !important;
}

.mat-card .mat-card-content {
    margin-bottom: 0 !important;
}

.mat-card .mat-card-content p:first-child {
    padding-top: 0;
}

.not-design-system .mat-card {
    padding: 16px !important;
}

.not-design-system .mat-card > * {
    margin: 0;
    padding: 0;
}

.flex-row-center-center {
    @include flex-layout(row, center, center);
}

.flex-row-end-center {
    @include flex-layout(row, flex-end, center);
}

.flex-row-center-center-sm {
    @media (max-width: 960px) {
        @include flex-layout(row, center, center);
    }
}

.flex-row-spacebetween-center {
    @include flex-layout(row, space-between, center);
}

.flex-row-start-center {
    @include flex-layout(row, flex-start, center);
}

.flex-row-start-start {
    @include flex-layout(row, flex-start, flex-start);
}

.flex-row-center-start {
    @include flex-layout(row, center, flex-start);
}

.flex-column-center-center {
    @include flex-layout(column, center, center);
}

.flex-column-start-center {
    @include flex-layout(column, flex-start, center);
}

.flex-column-center-start {
    @include flex-layout(column, center, flex-start);
}

.flex-column-center-center-sm {
    @media (max-width: 959px) {
        @include flex-layout(column, center, center);
    }
}

.flex-column-center-end {
    @include flex-layout(column, center, flex-end);
}

.flex-column-start-start {
    @include flex-layout(column, flex-start, flex-start);
}

.flex-column-spacebetween-center {
    @include flex-layout(column, space-between, center);
}

.flex-column-spacebetween-start {
    @include flex-layout(column, space-between, flex-start);
}

.mouseover-child {
    .mat-icon {
        color: rs-color-primary() !important;
        margin: auto !important;
    }
}

#dashboard app-header .mat-icon {
    display: block !important;
}

#dashboard header .rs-wrapper {
    max-width: 100%;
}

#dashboard app-footer .login-link {
    display: none;
}

.overlay {
    background-color: transparent !important;
}

.square {
    box-sizing: border-box;
}

.square,
.examples-picture > img {
    border: 2px solid rs-color-primary() !important;
    border-radius: 8px !important;
}

.picture-manager div {
    place-items: normal !important;
    align-items: normal !important;
    width: 100% !important;
}

.d-flex {
    display: flex !important;
    box-sizing: border-box;
    width: 100%;
}

.f-column {
    flex-direction: column;
}

.f-row {
    flex-direction: row;
}

.j-center {
    justify-content: center;
}

.j-between {
    justify-content: space-between;
}

.j-around {
    justify-content: space-around;
}

.j-start {
    justify-content: flex-start;
}

.j-end {
    justify-content: flex-end;
}

.a-center {
    align-items: center;
}

.a-between {
    align-items: space-between;
}

.a-around {
    align-items: space-around;
}

.a-start {
    align-items: flex-start;
}

.a-end {
    align-items: flex-end;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}
