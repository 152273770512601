@use 'SASS:map';

$dark-primary-text: #008048;
$light-primary-text: white;
$dark-secondary-text: #3d3286;
$light-secondary-text: white;
$error-color-primary: #ff000d;
$secondary-text: #4d4d4e;

$rs-secondary: (
    50: #eee6fa,
    100: #d2c2f4,
    200: #b49aed,
    300: #956fe7,
    400: #7b4ee1,
    500: #602cdb,
    600: #5427d5,
    700: #421fcc,
    800: #2d18c6,
    900: #0006bd,
    contrast: (
        50: $dark-secondary-text,
        100: $dark-secondary-text,
        200: $dark-secondary-text,
        300: $dark-secondary-text,
        400: $light-secondary-text,
        500: $light-secondary-text,
        600: $light-secondary-text,
        700: $light-secondary-text,
        800: $light-secondary-text,
        900: $light-secondary-text
    )
);

$rs-primary: (
    50: #dff6ed,
    100: #b0e9d2,
    200: #76dab6,
    300: #00cc99,
    400: #00c083,
    500: #00b46e,
    600: #00a463,
    700: #009255,
    800: #008048,
    900: #006131,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text
    )
);

$rs-warn: (
    100: $error-color-primary,
    700: $error-color-primary,
    contrast: (
        100: $dark-primary-text,
        700: $dark-primary-text
    )
);

@function rs-color-primary($variant: 300) {
    @return map.get($map: $rs-primary, $key: $variant);
}

@function rs-color-secondary($variant: 700) {
    @return map.get($map: $rs-secondary, $key: $variant);
}

$background: #f9f9f9;
$color-accent: #7c4dff;
$primary-text: #3a3a3a;
$font-in-dark: #e6e6e5;
$color-step: #ceccd4;
$color-icon-restart: #706f6f;
$background-selected-system: #e6e3e3;
$background-system-hover: #f2f0f0;
$color-line: rgba(0, 0, 0, 0.12);
$color-black-alpha-10: rgba(0, 0, 0, 0.1);
$color-black-alpha-12: rgba(0, 0, 0, 0.12);
$color-black-alpha-20: rgba(0, 0, 0, 0.2);
$color-black-alpha-30: rgba(0, 0, 0, 0.3);
$color-black-alpha-40: rgba(0, 0, 0, 0.4);
$color-black-alpha-50: rgba(0, 0, 0, 0.5);
$color-black-alpha-60: rgba(0, 0, 0, 0.6);
$color-black-alpha-70: rgba(0, 0, 0, 0.7);
$color-black-alpha-80: rgba(0, 0, 0, 0.8);
$color-black-alpha-90: rgba(0, 0, 0, 0.9);
$error-color-secondary: #faeded;
$warning-color-secondary: #fff8e3;
$info-color-secondary: #ebf1f8;
$success-color-secondary: #e7f9f1;
$warning-color-primary: #ffbf00;
$info-color-primary: #008bf5;
$success-color-primary: #02c20f;
$button-disable: rgba(175, 175, 175, 0.2);
$dark-background: rs-color-secondary();
