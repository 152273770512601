h1,
h2,
h3,
h4,
h5,
p,
li,
span {
    font-weight: normal;
}

h1 {
    font-size: 3.052rem;
    line-height: 120%;
}

h2 {
    font-size: 2.441rem;
    line-height: 120%;
}

h3 {
    font-size: 1.953rem;
    line-height: 130%;
}

h4 {
    font-size: 1.563rem;
    line-height: 140%;
}

h5 {
    font-size: 1.25rem;
    line-height: 150%;
}

p,
li {
    font-size: 1.3125rem;
    line-height: 150%;
    margin-top: 0.5rem;
}
