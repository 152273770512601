@import './assets/styles/material-theme';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'apps/solido-app/src/assets/styles/css-directives';
@import 'apps/solido-app/src/assets/styles/object';
@import 'apps/solido-app/src/assets/styles/cubic-bezier';
@import 'apps/solido-app/src/assets/styles/list-base';
@import 'apps/solido-app/src/assets/styles/typography';
@import 'apps/solido-app/src/assets/styles/colors-typography';
@import 'apps/solido-app/src/assets/styles/images';
@import 'apps/solido-app/src/assets/styles/header';

@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/material-icons/MaterialIcons-outlined.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* stylelint-disable font-family-no-missing-generic-family-keyword */
.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* stylelint-enable font-family-no-missing-generic-family-keyword */

* {
    margin: 0;
}

html,
body {
    background-color: $background;
    height: 100%;
    margin: 0;
    color: $primary-text;
    font-family: 'Roboto', sans-serif;
    cursor: default;
}

app-header + * {
    margin-top: 120px !important;
}

.secondary-landing-system-text {
    opacity: 0.7 !important;
}

@media (max-width: 959px) {
    app-header + * {
        margin-top: 80px !important;
    }
}

/* General styles */

.tooltip-payment-queue {
    background: $primary-text !important;
    color: white !important;
    font-size: 0.9rem;
    white-space: pre-wrap;
}

.help-options-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 14px;
    width: 100%;
    height: 100%;
    padding: 20px 0 0;

    @media (max-width: 665px) {
        flex-direction: column;
        align-items: center;
    }
}

::ng-deep #crisp-chatbox a {
    bottom: 64px !important;
}

.container-info-comission {
    align-items: center;
    width: 100%;
    display: flex;
}

.btn-instructions {
    padding-top: 12px;

    button {
        color: $secondary-text;
    }

    mat-icon {
        padding-right: 4px;
        position: relative;
        top: -1px;
    }
}

.active-button {
    background-color: #d9d9d93d;
}

.secondary-text {
    opacity: 0.7 !important;
}

.tooltip-info {
    background-color: $info-color-secondary;
    color: $primary-text !important;
    font-size: 0.9rem;
    white-space: pre-wrap;
}

.tooltip-payment-queue,
.mat-button {
    background-color: rs-color-primary();
    color: white;
}

.divider {
    position: relative !important;
}

.mat-tab-link {
    color: black !important;
    opacity: 0.8 !important;
}

.disabled-tab {
    opacity: 0.2 !important;
}

.mat-checkbox-inner-container {
    margin-top: 4px !important;
}

.mat-stroked-button {
    border-width: 0 !important;
    color: rs-color-primary();
}

.mat-button,
.mat-stroked-button {
    min-width: 86px !important;
    font-size: 1rem;
    padding: 0 16px;
    font-weight: 500;
}

/* stylelint-disable selector-type-no-unknown */

auth-message p,
auth-message .mat-button {
    margin: 0 !important;
}

.state-container {
    img {
        margin-right: 4px;
    }

    p {
        font-size: 0.875rem;
    }
}

auth-message .mat-button {
    color: white;
    background-color: rs-color-secondary(400) !important;
}

jam-refresh .mat-button,
jam-search-input .mat-button {
    min-width: fit-content !important;
    background-color: transparent !important;
}

jam-refresh .mat-button,
jam-search-input .mat-hint {
    color: $primary-text;
}

.text-center {
    text-align: center;
}

*:focus {
    outline-color: rs-color-primary();
}

*:active {
    outline: none;
}

.link {
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    color: rs-color-primary(300);
    transition: all $primary-cubic 100ms;
}

form .text-exits-email {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.link:hover {
    color: rs-color-primary(600);
    border-color: rs-color-primary(600);
}

/* stylelint-enable selector-type-no-unknown */

.paragraph-with-overflow {
    white-space: break-spaces;
    overflow: hidden;
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* stylelint-enable */
    text-overflow: ellipsis;
}

.mat-button:disabled {
    background-color: $button-disable;
}

.tel-pref .mat-button {
    background-color: transparent !important;
    min-width: auto !important;
    margin-bottom: 1px !important;
}

.dark-background .mat-button,
.dark-background.mat-button,
.dark-background .mat-stroked-button,
.dark-background.mat-stroked-button {
    font-weight: bold;
    height: 50px;
    font-size: 1.25rem;
}

.mat-snack-bar-container span {
    color: white;
    font-size: 1.05rem;
}

.mat-step-label-active {
    color: rs-color-primary() !important;
}

.mat-step-label {
    color: rgba(0, 0, 0, 0.2);
}

.mat-snack-bar-container .mat-button {
    background: 0 0 !important;
    font-weight: bold;
    color: rs-color-secondary(400);
}

.mat-step-header .mat-step-icon-selected {
    background-color: rs-color-primary() !important;
}

.mat-step-header .mat-step-icon {
    background-color: rgba(0, 0, 0, 0.2);
}

.button-guest-actions .mat-button {
    background: 0 0 !important;
    color: rs-color-primary();
}

/* stylelint-disable max-nesting-depth,selector-max-compound-selectors,no-descending-specificity */
.auth-wrapper {
    h3 {
        font-size: 1.6rem;
    }

    .mat-card {
        padding: 24px !important;
    }

    .mat-card > * {
        margin: 0;
        padding: 0;

        &:last-child {
            padding-bottom: 0;
        }
    }

    jam-submit mat-progress-spinner circle,
    mat-spinner circle {
        stroke: white !important;
    }

    .mat-button,
    .button-guest-actions .mat-button {
        height: 48px !important;
        min-height: 48px !important;
    }
}

/* stylelint-enable max-nesting-depth,selector-max-compound-selectors,no-descending-specificity */

.alert-component-container.error,
.mat-snack-bar-container.error {
    background: $error-color-primary;
}

.alert-component-container.warning,
.mat-snack-bar-container.warning {
    background: $warning-color-primary;
}

.alert-component-container.info,
.mat-snack-bar-container.info {
    background: $info-color-primary;
}

.alert-component-container.success,
.mat-snack-bar-container.success {
    background: $success-color-primary;
}

.mat-snack-bar-container.error .mat-button {
    color: $error-color-primary;
}

.mat-snack-bar-container.warning .mat-button {
    color: $warning-color-primary;
}

.mat-snack-bar-container.info .mat-button {
    color: $info-color-primary;
}

.mat-snack-bar-container.success .mat-button {
    color: $success-color-primary;
}

.default-space-between-components {
    padding-top: 72px;
    padding-bottom: 72px;

    @media (max-width: 600px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}

.button-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    box-sizing: border-box;
}

/* stylelint-disable no-descending-specificity */
.button-actions > *:nth-child(2) {
    margin: 0 0 0 1rem;
}

/* stylelint-enable no-descending-specificity */

.button-guest-actions .mat-button:last-child {
    background-color: rs-color-primary() !important;
    color: white;
    width: auto !important;
}

@media (max-width: 1280px) {
    jam-floating-button a {
        bottom: 64px !important;
    }
}

@media (max-width: 400px) {
    form .text-exits-email {
        width: 80%;
    }

    .button-actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .button-actions > * .mat-button {
        width: 100%;
    }

    .button-actions > *:nth-child(1) {
        margin: 1rem 0 0;
        order: 2;
    }

    .button-actions > *:nth-child(2) {
        margin: 0;
    }
}

.terms-and-notification-bell {
    position: absolute;
    top: 24px;
    width: auto;
    max-width: 1200px;
    right: 24px;
    z-index: 12;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 979px) {
    .terms-and-notification-bell {
        top: 12px;
    }

    .terms-and-notification-bell .mat-icon-button {
        color: white;
    }
}

.dark-background .mat-button:disabled {
    background-color: $button-disable;
}

.flat-button .mat-button:disabled {
    background-color: rgba(rs-color-primary(200), 0.2);
}

.mat-menu-item {
    background-color: white;
}

.width-100 {
    width: 100% !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-48 {
    padding: 48px;
}

.mat-dialog-container {
    padding: 0 !important;
}

// General material styles

.mat-checkbox-layout {
    white-space: normal !important;
}

.mat-radio-label {
    white-space: normal !important;
}

.rs-wrapper-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 24px;
}

.container-best-rates {
    width: 100%;

    @media (min-width: 600px) {
        width: 479px;
    }

    @media (min-width: 1279px) {
        width: 532px;
    }
}

.centered-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.buttons-calculator-container .more-options-button {
    min-width: 50px !important;
    max-width: 50px !important;
    height: 50px;
    color: $primary-text;

    .mat-icon {
        font-size: 2.125rem;
        width: 34px;
        height: 34px;
    }
}

.message-error-first-input,
.message-error-second-input {
    position: absolute;
    width: 95%;
    z-index: 1;
}

.message-error-first-input {
    top: -36px;
}

.message-error-second-input {
    bottom: 30px;
}

.loading-calculator {
    box-sizing: border-box !important;
    display: flex;
    width: 532px;
    padding: 16px;
    margin-bottom: 8px;
    align-items: center;
}

.mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.38);
}

.mat-slide-toggle-thumb {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background-color: #fafafa;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: rs-color-primary();
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
}

.slide-toggle {
    width: 100%;
}

jam-list-base-common-infinite-scroll .mat-header-row,
jam-list-base-common-paginator .mat-header-row {
    display: none;
}

.slide-toggle label {
    flex-direction: row-reverse;
    justify-content: space-between;
}

mat-paginator .mat-form-field-infix {
    height: 24px;
}

.mat-icon-button.big-icon-button {
    margin: -16px 0 -15px;
    width: 38px;
    height: 38px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid $color-black-alpha-30;
    border-left: 0;
    border-right: 0;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;

    .mat-icon {
        position: relative;
        top: -3px;
        padding: 6px 5px;
        color: $color-black-alpha-50;
        transition: 0.1s;
    }

    .mat-icon:hover {
        color: $color-black-alpha-70;
    }
}

.button-move-closed {
    min-width: fit-content;
    height: 0%;
    width: 0%;
    transition: all 0.2s $primary-cubic;
}

.button-move-open {
    width: 100%;
    transition: all 0.3s $primary-cubic;
    margin: 12px 0 !important;
}

.rotate {
    transform: rotate(180deg);
}

.mat-fab.mat-accent {
    background-color: rs-color-primary();
}

.list-and-button-container {
    position: relative;
}

/* stylelint-disable no-descending-specificity */
.list-and-button-container > *:first-child {
    margin-bottom: 12px;
}

/* stylelint-enable no-descending-specificity */

.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: rs-color-primary();
}

:host ::ng-deep .mat-tab-link {
    margin-bottom: 24px;
    border-bottom: 1px solid $color-line;
}

.mat-card-content {
    box-sizing: border-box;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
    color: white;
}

.waiting-payment-width {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.item-trend {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    height: 22px;
}

.container-trend {
    display: flex;
    position: relative;
    justify-content: flex-start;
    border-radius: 4px;
    width: 100%;
}

.text-trend {
    font-size: 0.8rem;
    margin-top: 0;
    opacity: 1;
    white-space: break-spaces;
    overflow: hidden;
    /* stylelint-disable */
    display: -webkit-box;
    /* stylelint-enable */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.icon-trend {
    font-size: 1rem;
    width: 20px;
    height: 16px;
}

@media (max-width: 600px) {
    .loading-calculator {
        width: 292px;
    }
}

.full-screen-container {
    h1 {
        padding-top: 24px;
        padding-bottom: 12px;
        margin: 0;
    }

    .descent-paragraph {
        padding: 12px 0 24px !important;
    }
}

.buttons-calculator-container {
    padding: 16px 0 0 !important;
    grid-gap: 16px !important;
    grid-template-columns: 50px 252px;
    width: 65%;
    left: 19%;
    position: relative;

    @media (max-width: 1278px) {
        grid-template-columns: 50px 224px;
        left: 18%;
    }

    @media (max-width: 599px) {
        grid-template-columns: 50px 1fr;
        width: 100%;
        left: 0;
    }
}

.buttons-calculator-container .mat-stroked-button {
    padding: 0 !important;
}

.buttons-calculator-container .dark-background {
    grid-column-end: span 1;
}

.skeleton-space {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
}

/* stylelint-disable selector-no-qualifying-type, no-descending-specificity */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.pb-72 {
    padding-bottom: 72px;
}

.pv-72 {
    padding-top: 72px;
    padding-bottom: 72px;
}

.pt-72 {
    padding-top: 72px;
}

/* stylelint-disable-next-line selector-type-no-unknown */
markdown {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    ul,
    ol,
    li,
    a,
    blockquote,
    code,
    pre,
    em,
    strong,
    del {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }

    /* img,
    table,
    th,
    td,
    tr */
}
