// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '@angular/cdk';

@import 'apps/solido-app/src/assets/styles/colors';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
// TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
// TODO(mdc-migration): Remove legacy-core once all legacy components are migrated
@include mat.legacy-core();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$solido-primary: mat.define-palette($rs-primary, 300, 100, 400);
$solido-accent: mat.define-palette($rs-secondary, 700, 100, 400);

$solido-warn: mat.define-palette($rs-warn, 100);
/* stylelint-disable */
$solido-theme: mat.define-light-theme(
    (
        color: (
            primary: $solido-primary,
            accent: $solido-accent,
            warn: $solido-warn
        )
    )
);
$solido-theme-inverse: mat.define-light-theme(
    (
        color: (
            primary: $solido-accent,
            accent: $solido-primary,
            warn: $solido-warn
        )
    )
);
/* stylelint-enable */

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($solido-theme);
@include mat.legacy-button-theme($solido-theme);
// @include mat.button-theme($solido-theme);
// @include mat.legacy-button-toggle-theme($solido-theme);
@include mat.legacy-card-theme($solido-theme);
@include mat.legacy-checkbox-theme($solido-theme-inverse);
// @include mat.divider-theme($solido-theme);
@include mat.legacy-form-field-theme($solido-theme);
@include mat.legacy-progress-spinner-theme($solido-theme);
@include mat.legacy-chips-theme($solido-theme);
@include mat.legacy-input-theme($solido-theme);
@include mat.legacy-progress-bar-theme($solido-theme);
@include mat.legacy-radio-theme($solido-theme);
@include mat.legacy-autocomplete-theme($solido-theme);
@include mat.legacy-select-theme($solido-theme);
// @include mat.stepper-theme($solido-theme);
// @include mat.ripple-theme($solido-theme);
@include mat.legacy-menu-theme($solido-theme);
// @include mat.legacy-badge-theme($solido-theme);
@include mat.legacy-core-theme($solido-theme);
@include mat.legacy-tooltip-theme($solido-theme);
