@import './colors';

/* stylelint-disable selector-type-no-unknown, selector-no-qualifying-type */

table {
    border-collapse: collapse;
}

rs-table-bootstrap table {
    border-collapse: initial;
}

mat-cell,
mat-header-cell {
    padding: 0 8px;
    box-sizing: border-box;
}

.mat-table-sticky:first-child {
    border-right: 1px solid grey;
}

.mat-table-sticky:last-child {
    border-left: 1px solid grey;
}

mat-cell.number-cell,
mat-header-cell.number-cell {
    display: flex;
    justify-content: flex-end;
    text-align: end;
}

mat-cell.number-cell strong,
mat-cell.number-cell div,
mat-cell.number-cell span {
    text-align: right;
    width: 100%;
}

mat-cell.complex-cell,
mat-header-cell.complex-cell {
    max-width: 150px;
}

// Alineacion en las tablas
%mat-cell-in-common {
    flex: 1 1 100%;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
    display: flex;
    max-width: 15% !important;
}

.mat-cell-left {
    @extend %mat-cell-in-common;

    place-content: center flex-start !important;
}

.mat-cell-right {
    @extend %mat-cell-in-common;

    place-content: center flex-end !important;
}

.mat-cell-center {
    @extend %mat-cell-in-common;

    place-content: center !important;
}

mat-header-row > .mat-column-select,
mat-row > .mat-column-select {
    max-width: 16px !important;
    padding-right: 24px !important;
}

mat-header-row > .cdk-column-actions,
mat-row > .cdk-column-actions,
mat-header-row > .mat-column-actions,
mat-row > .mat-column-actions {
    max-width: 40px !important;
    min-width: 40px !important;
    padding-right: 16px !important;
}

@media screen and (max-width: 599px) {
    mat-header-row > .mat-column-actions,
    mat-row > .mat-column-actions {
        max-width: 40px !important;
        min-width: 40px !important;
        padding-right: 0 !important;
    }
}

mat-header-row {
    border-color: $color-black-alpha-20;
    color: $color-black-alpha-50;
}

mat-paginator {
    color: $color-black-alpha-50;
}

.mat-paginator-page-size-label {
    margin-right: 8px !important;
}

.mat-paginator-page-size-select {
    margin: 0 !important;
}

.paginator-new-list .mat-form-field-flex {
    height: 48px;
}

.paginator-new-list .mat-form-field-wrapper {
    padding-bottom: 0;
}

.paginator-new-list .mat-form-field-infix {
    border-top: 12px solid transparent;
}

.paginator-new-list .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 8px 0;
}

.paginator-new-list .mat-select-arrow-wrapper {
    top: 2px;
    position: relative;
}

.system-cell {
    max-width: 150px;
}

/* stylelint-enable selector-type-no-unknown, selector-no-qualifying-type */
