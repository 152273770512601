#dashboard {
    app-header {
        display: block;
        width: 100%;
    }

    app-header + * {
        margin-top: 0 !important;
    }

    app-header .rs-wrapper-v4 {
        padding-top: 0;
        padding-bottom: 0;
    }

    @media (min-width: 1280px) {
        header {
            position: absolute;
            right: 0;
            z-index: 2;
            height: fit-content;
            width: fit-content;
            background-color: transparent;
            box-shadow: none;
        }

        header app-logo-redirects {
            display: none;
        }

        .dropdown {
            .icon {
                transform: translateY(-5px);
                opacity: 0;
            }
        }

        .dropdown:hover .icon {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .user-dashboard-menu {
        display: block;
    }

    .icon-menu-container,
    .wiki-links,
    .user-options-container {
        display: none !important;
    }

    .dropdown {
        cursor: pointer;
        z-index: 4;
        display: flex;
        min-width: 106px;
        flex-direction: row;
    }

    .dropdown .icon {
        margin-left: 8px;
        transition-duration: 0.4s;
    }

    .info-profile-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .info-profile-container .name {
        font-size: 0.875rem;
    }

    .info-profile-container .level {
        font-size: 0.75rem;
        opacity: 0.5;
    }

    app-rs-avatar {
        margin-right: 10px;
    }
}
