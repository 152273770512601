h1,
h2,
h3,
h4,
h5,
.color-heading {
    color: #3a3a3a;
}

p,
li {
    color: #888889;
}
