.hero-img {
    max-width: 400px;
    height: auto;
    padding: 24px;

    @media (max-width: 665px) {
        width: 80%;
    }
}

.card-img {
    width: 143px;
    height: 120px;
}

.article-img {
    width: 100%;
    height: 161px;

    @media (max-width: 665px) {
        width: 100%;
        height: 86px;
    }
}

.info-block-img {
    width: 350px;
    height: 222px;
}
